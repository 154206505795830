import React from "react"
import { PageProps } from "gatsby"
import AboutImage from "../components/AboutImage"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero, {
  HeroSection,
  HeroEmoji,
  HeroTitle,
  HeroContent,
} from "../styles/Hero"

const AboutPage = ({}: PageProps) => (
  <Layout>
    <SEO title="About" />

    <HeroSection>
      <HeroEmoji>🏂 🏕 🐶</HeroEmoji>
      <HeroTitle>
        Snowboarder, adventurer
        <br />
        and dog lover.
      </HeroTitle>
    </HeroSection>

    <AboutImage />

    <HeroSection>
      <HeroContent>
        <p>
          I'm passionate about using the latest technologies to create
          innovative user experiences. A hands-on approach is the best way for
          me to learn, which is why I love to try new things and I am able to
          grasp ideas quickly. Teaching is a great way to solidify what I'm
          learning, that is one of many reasons I enjoy guiding new developers
          through studying useful skills and best practices to lead them to a
          successful career.
        </p>

        <p>
          When I'm not hacking away on my computer I like to do art (mostly
          drawing and watercolors) and make music (both analog and digital).
          Creating art and playing instruments are a great way to de-stress and
          give my eyes a break from all the screen time, but also, they give me
          an outlet to express myself in ways more than words sometimes can.
          They also help to keep the creative side of my brian sharp. If you're
          interested in my off hours creations, you should{" "}
          <a href="https://soundcloud.com/andersryanc" target="_blank">
            have a listen
          </a>{" "}
          or{" "}
          <a href="https://www.instagram.com/made.by.mr.a" target="_blank">
            take a gander
          </a>
          !
        </p>

        <p style={{ marginTop: "4rem" }}>
          <strong>But, enough about me, I want to hear about you!</strong>
          <br />
          Tell me how I can help you:{" "}
          <a href="mailto:ryan@mranderson.io">ryan@mranderson.io</a>
        </p>
      </HeroContent>
    </HeroSection>

    <Hero
      title="Skills &amp; Expertise"
      content={
        <>
          <p>HTML, CSS, JS</p>
          <p>
            <strong>TypeScript</strong>, SASS, LESS, CSS-in-JS,
            <strong>React</strong>, React Native, Storybook, Node.JS, Express, Next.JS,
            Gatsby, <strong>Go</strong>, Python, Flask
          </p>
          <p>
            Ubuntu, <strong>Docker</strong>, Docker Swarm, Swarmpit,
            Traefik, <strong>Kubernetes</strong>, Apache, <strong>Nginx</strong>
          </p>
          <p>
            Digital Ocean, <strong>AWS</strong> (EC2, ECS, EB, RDS, Cognito,
            Lambda, S3, Route53, API Gateway, SNS, CloudFront),{" "}
            <strong>GCP</strong> (GCE, GKE, SQL, Cloud Storage, Cloud Tasks, DataProc, BigQuery)
          </p>
          <p>
            ElasticSearch, Kibana, <strong>Grafana</strong>, Prometheus,
            BugSnag, Google Analytics
          </p>
          <p>
            MySQL, <strong>PostgreSQL</strong>, MongoDB, DynamoDB,{" "}
            <strong>GraphQL</strong>, Prisma, Redis
          </p>
          <p>
            PHP, Laravel, WordPress, WooCommerce, WPBakery Visual Composer,
            Elementor, Custom Themes + Plugins, Roots.io (Trellis + Bedrock)
          </p>
          <p>
            Automation, Bash Scripting, Ansible, Git, <strong>GitLab CI</strong>
            , Git Flow
          </p>
          <p>Adobe Suite, Sketch, <strong>Figma</strong>, OmniGraffle, Procreate</p>
        </>
      }
    />
  </Layout>
)

export default AboutPage
