import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const hideDesktop = css`
  display: none;

  @media (max-width: 480px) {
    display: block;
  }
`

const hideMobile = css`
  display: block;

  @media (max-width: 480px) {
    display: none;
  }
`

const AboutImage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "about-wide-v2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aboutMobile: file(relativePath: { eq: "about-mobile-v2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Img fluid={data.aboutImage.childImageSharp.fluid} css={hideMobile} />
      <Img fluid={data.aboutMobile.childImageSharp.fluid} css={hideDesktop} />
    </>
  )
}

export default AboutImage
